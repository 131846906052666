import { React, useState, useEffect } from "react";
// import router from "next/router";
import { useRouter } from "next/router";

function Searcher() {
  const router = useRouter();

  const [selected_tab, set_selected_tab] = useState("spareparts");
  const [product_categories, set_product_categories] = useState({});
  const [product_brands, set_product_brands] = useState({});

  const [brand, setBrand] = useState();
  const [appliance, setAppliance] = useState();
  const [range, setRange] = useState();

  const [spareparts_brands, set_spareparts_brands] = useState({});
  const [spareparts_filters, set_spareparts_filters] = useState({});
  const [spareparts_filters_secondlevel, set_spareparts_filters_secondlevel] =
    useState({});

  const [selected_category_first_level, set_selected_category_first_level] =
    useState();
  const [selected_category_second_level, set_selected_category_second_level] =
    useState();
  const [
    selected_category_second_level_url,
    set_selected_category_second_level_url,
  ] = useState();
  const [selected_brand_level, set_selected_brand_level] = useState();

  const [selected_sparepart_brand, set_selected_sparepart_brand] = useState();
  const [selected_sparepart_second_level, set_selected_sparepart_second_level] =
    useState();
  const [selected_sparepart_third_level, set_selected_sparepart_third_level] =
    useState();

  const change_selected_tab = (item) => {
    set_selected_tab(item);
  };

  const change_selected_category_first_level = (item) => {
    set_selected_category_first_level({
      id: item.id,
      url: item.url,
    });
  };

  const change_selected_category_second_level = (item) => {
    set_selected_category_second_level({
      id: item.id,
      url: item.url,
    });
    product_brands_dropdown_api_call(item.url);
  };

  const change_selected_brand_level = (item) => {
    if (item != false) {
      set_selected_brand_level({
        id: item.id,
        url: item.url,
      });
    }
  };

  const change_selected_sparepart_brand = (item) => {
    // console.log(item);
    // clear the other two
    set_spareparts_filters({});
    set_spareparts_filters_secondlevel({});
    if (item != false) {
      set_selected_sparepart_brand({
        id: item.id,
        url: item.url,
      });
      spareparts_secondlevel_dropdown_api_call(item);
    }
  };
  const change_selected_sparepart_second_level = (item) => {
    set_spareparts_filters_secondlevel({});
    if (item != false) {
      set_selected_sparepart_second_level({
        id: item.id,
        url: item.url,
      });
      spareparts_filters_thirdlevel_dropdown_api_call(item);
    }
  };
  const change_selected_sparepart_third_level = (item) => {
    set_selected_sparepart_third_level({});
    if (item != false) {
      set_selected_sparepart_third_level({
        id: item.id,
        url: item.url,
      });
    }
  };

  const go_to_filter_page = () => {
    router.push(
      {
        pathname: "/category/" + selected_category_second_level.url,
        query: {
          ...router.query,
          filters: selected_brand_level
            ? JSON.stringify([selected_brand_level.id])
            : "",
        },
      },
      undefined,
      { shallow: true }
    );
  };

  const go_to_spareparts_page = () => {
    var chosen_filters = [
      parseInt(selected_sparepart_second_level.id),
      parseInt(selected_sparepart_third_level.id),
    ];

    router.push(
      {
        pathname: "/spareparts/" + selected_sparepart_brand.url,
        query: { ...router.query, filters: JSON.stringify(chosen_filters) },
      },
      undefined,
      { shallow: true }
    );
  };

  const product_categories_dropdown_api_call = async () => {
    const res = fetch(
      process.env.NEXT_PUBLIC_API_URL + "/taxonomies/category",
      {
        headers: {
          "Content-Type": "application/json",
        },
        method: "GET",
      }
    )
      .then((response) => {
        // reject not ok response
        if (!response.ok) {
          return Promise.reject(response);
        }
        return response.json(); // or return response.text()
      })
      .catch(async (response) => {
        const error = await response.json().then((text) => text);
        return Promise.reject(error);
      })
      .then((data) => {
        set_product_categories(data.data);
      })
      .catch((error) => {
        console.warn(error);
      });
  };
  const product_brands_dropdown_api_call = async (url) => {
    var body = {
      type: "brand",
      slug: url,
    };
    const res = fetch(process.env.NEXT_PUBLIC_API_URL + "/taxonomy-filtered", {
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
      method: "POST",
    })
      .then((response) => {
        // reject not ok response
        if (!response.ok) {
          return Promise.reject(response);
        }
        return response.json(); // or return response.text()
      })
      .catch(async (response) => {
        const error = await response.json().then((text) => text);
        return Promise.reject(error);
      })
      .then((data) => {
        set_product_brands(data.data);
      })
      .catch((error) => {
        console.warn(error);
      });
  };
  const spareparts_brands_dropdown_api_call = async () => {
    const res = fetch(
      process.env.NEXT_PUBLIC_API_URL + "/taxonomies/sparepart",
      {
        headers: {
          "Content-Type": "application/json",
        },
        method: "GET",
      }
    )
      .then((response) => {
        // reject not ok response
        if (!response.ok) {
          return Promise.reject(response);
        }
        return response.json(); // or return response.text()
      })
      .catch(async (response) => {
        const error = await response.json().then((text) => text);
        return Promise.reject(error);
      })
      .then((data) => {
        set_spareparts_brands(data.data);
      })
      .catch((error) => {
        console.warn(error);
      });
  };
  const spareparts_secondlevel_dropdown_api_call = async (item) => {
    // console.log("Hi World");
    // console.log(item);
    var body = {
      type: "sparepart",
      //   type: "brand",
      slug: item.url,
    };
    const res = fetch(
      process.env.NEXT_PUBLIC_API_URL + "/taxonomies-with-childs/sparepart/" + item.url,
      {
        headers: {
          "Content-Type": "application/json",
        },
        //   body: JSON.stringify(body),
        method: "GET",
      }
    )
      .then((response) => {
        // reject not ok response
        if (!response.ok) {
          return Promise.reject(response);
        }
        return response.json(); // or return response.text()
      })
      .catch(async (response) => {
        const error = await response.json().then((text) => text);
        return Promise.reject(error);
      })
      .then((data) => {
        setBrand(data.data.url);
        set_spareparts_filters(data.data.childs);
        set_spareparts_filters_secondlevel(data.data.subchild);
      })
      .catch((error) => {
        console.warn(error);
      });
  };

  const spareparts_filters_thirdlevel_dropdown_api_call = async (item) => {
    // add in the other things
    const filters = [selected_sparepart_brand.id];

    var body = {
      filters: filters,
      type: "sparepart-filter",
      slug: item.url,
    };
    const res = fetch(process.env.NEXT_PUBLIC_API_URL + "/taxonomy-filtered", {
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
      method: "POST",
    })
      .then((response) => {
        // reject not ok response
        if (!response.ok) {
          return Promise.reject(response);
        }
        return response.json(); // or return response.text()
      })
      .catch(async (response) => {
        const error = await response.json().then((text) => text);
        return Promise.reject(error);
      })
      .then((data) => {
        var result = data.data.filter((obj) => {
          return obj.id == item.id;
        });
        set_spareparts_filters_secondlevel(result);
      })
      .catch((error) => {
        console.warn(error);
      });
  };

  useEffect(() => {
    product_categories_dropdown_api_call();
    spareparts_brands_dropdown_api_call();
  }, []);

  const go_to_new_spareparts_page = () => {
    if (!brand) {
      alert("Please Select Brand");
      return false;
    } else if (!range) {
      alert("Please Select Range / Model");
      return false;
    } else {
      const rangeobj = spareparts_filters_secondlevel.find(obj => obj.url == range);
      const range_parent_url = spareparts_filters.find(obj => obj.id == rangeobj.parent_id);

      router.push("/sparepart/" + brand + "/" + range_parent_url.url + "/" + range);
    }
  };

  return (
    <>
      <div className="bg-white border border-gray-300 rounded-lg h-full">
        <nav className="tabs grid grid-cols-2">
          <button
            onClick={() => change_selected_tab("spareparts")}
            className={
              selected_tab == "spareparts"
                ? "bg-white active tab text-gray-900 py-3 block focus:outline-none font-bold text-lg rounded-tl-lg"
                : "bg-gray-200 tab text-gray-900 py-3 block focus:outline-none font-bold text-lg rounded-tl-lg hover:bg-gray-100 transition duration-150 ease-in-out"
            }
          >
            Spares Finder
          </button>
          <button
            onClick={() => change_selected_tab("products")}
            className={
              selected_tab == "products"
                ? "bg-white active tab text-gray-900 py-3 block focus:outline-none font-bold text-lg rounded-tr-lg"
                : "bg-gray-200 tab text-gray-900 py-3 block focus:outline-none font-bold text-lg rounded-tr-lg hover:bg-gray-100 transition duration-150 ease-in-out"
            }
          >
            Product Finder
          </button>
        </nav>
        <div id="panels" className="p-8" style={{height: '90%'}}>
          <div className="panel-1 tab-content active space-y-4 grid content-between h-full" style={{display: 'grid'}}>
            {selected_tab == "spareparts" ? (
              <>
              <div>
                <div>
                  <label className="field-label">Brand / Manufacturer</label>

                  {spareparts_brands && spareparts_brands.length > 0 ? (
                    <select
                      onChange={(e) => {
                        change_selected_sparepart_brand(
                          JSON.parse(e.target.value)
                        );
                      }}
                      className="dropdown-field"
                    >
                      <option value={false}>Please Select</option>
                      {spareparts_brands.map((category) => (
                        <option
                          key={category.id}
                          value={
                            '{"id":' +
                            category.id +
                            ', "url":"' +
                            category.url +
                            '"}'
                          }
                        >
                          {category.name}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <select
                      className="dropdown-field cursor-not-allowed"
                      disabled
                    ></select>
                  )}
                </div>
                <div className="mt-5">
                  <label className="field-label">Range / Model</label>
                  {spareparts_filters_secondlevel &&
                    spareparts_filters_secondlevel.length > 0 &&
                  selected_sparepart_brand ? (
                    <>
                      <select
                        onChange={(e) => setRange(e.target.value)}
                        className="dropdown-field cursor-pointer"
                      >
                        <option value={false}>Please Select</option>
                        {spareparts_filters_secondlevel.map(
                          (item) => (
                            <option key={item.id} value={item.url}>
                              {item.name}
                            </option>
                          )
                          //   category.id == 249
                          //     ? category.map((item) => (
                          //         <option
                          //           key={item.id}
                          //           value={
                          //             '{"id":' +
                          //             item.id +
                          //             ', "url":"' +
                          //             item.url +
                          //             '"}'
                          //           }
                          //         >
                          //           {item.name}
                          //         </option>
                          //       ))
                          //     : ""
                        )}
                      </select>
                    </>
                  ) : (
                    <select
                      className="dropdown-field cursor-not-allowed"
                      disabled
                    ></select>
                  )}
                </div>
                </div>
                {/* <div>
                  <label className="field-label">Spare Part Range</label>
                  {spareparts_filters_secondlevel &&
                  spareparts_filters_secondlevel.length > 0 &&
                  selected_sparepart_second_level ? (
                    <>
                      <select
                        onChange={(e) => {
                          change_selected_sparepart_third_level(
                            JSON.parse(e.target.value)
                          );
                        }}
                        className="dropdown-field cursor-pointer"
                      >
                        <option value={false}>Please Select</option>
                        {spareparts_filters_secondlevel.map((category) =>
                          category.children.map((item) => (
                            <option
                              key={item.id}
                              value={
                                '{"id":' +
                                item.id +
                                ', "url":"' +
                                item.url +
                                '"}'
                              }
                            >
                              {item.name}
                            </option>
                          ))
                        )}
                      </select>
                    </>
                  ) : (
                    <select
                      className="dropdown-field cursor-not-allowed"
                      disabled
                    ></select>
                  )}
                </div> */}

                <button
                  onClick={() => go_to_new_spareparts_page()}
                  className="btn btn-primary btn-lg w-full mt-2"
                >
                  Find Spare Parts
                </button>
              </>
            ) : (
              <>
                <div>
                  <label className="field-label">Product Category</label>

                  {product_categories && product_categories.length > 0 ? (
                    <select
                      onChange={(e) => {
                        change_selected_category_first_level(
                          JSON.parse(e.target.value)
                        );
                      }}
                      className="dropdown-field cursor-pointer"
                    >
                      <option>Please Select</option>
                      {product_categories.map((category) =>
                        category.parent_id == 1 ? (
                          <option
                            key={category.id}
                            value={
                              '{"id":' +
                              category.id +
                              ', "url":"' +
                              category.url +
                              '"}'
                            }
                          >
                            {category.name}
                          </option>
                        ) : (
                          ""
                        )
                      )}
                    </select>
                  ) : (
                    ""
                  )}
                </div>
                <div>
                  <label className="field-label">Product Type</label>

                  {product_categories &&
                  product_categories.length > 0 &&
                  selected_category_first_level ? (
                    <select
                      onChange={(e) => {
                        change_selected_category_second_level(
                          JSON.parse(e.target.value)
                        );
                      }}
                      className="dropdown-field cursor-pointer"
                    >
                      <option>Please Select</option>
                      {product_categories.map((category) =>
                        category.id == selected_category_first_level.id
                          ? category.children.map((item) => (
                              <option
                                key={item.id}
                                value={
                                  '{"id":' +
                                  item.id +
                                  ', "url":"' +
                                  item.url +
                                  '"}'
                                }
                              >
                                {item.name}
                              </option>
                            ))
                          : ""
                      )}
                    </select>
                  ) : (
                    <select
                      className="dropdown-field cursor-not-allowed"
                      disabled
                    ></select>
                  )}
                </div>
                <div>
                  <label className="field-label">Brand / Manufacturer</label>
                  {product_brands &&
                  product_brands.length > 0 &&
                  selected_category_second_level ? (
                    <select
                      onChange={(e) => {
                        change_selected_brand_level(JSON.parse(e.target.value));
                      }}
                      className="dropdown-field cursor-pointer"
                    >
                      <option>All Brands</option>
                      {product_brands.map((category) =>
                        category.children
                          ? category.children.map((item) => (
                              <option
                                key={item.id}
                                value={
                                  '{"id":' +
                                  item.id +
                                  ', "url":"' +
                                  item.url +
                                  '"}'
                                }
                              >
                                {item.name}
                              </option>
                            ))
                          : ""
                      )}
                    </select>
                  ) : (
                    <select
                      className="dropdown-field cursor-not-allowed"
                      disabled
                    ></select>
                  )}
                </div>

                <button
                  onClick={() => go_to_filter_page()}
                  className="btn btn-primary btn-lg btn-block mt-2"
                >
                  Find Products
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Searcher;