import Link from 'next/link'
import parse from 'html-react-parser'
import dynamic from 'next/dynamic'
import Scripts from '../components/scripts'

import Header from '../components/header'
import Slider from '../components/slider'
import Searcher from '../components/searcher/searcher'

const Footer = dynamic(() => import('../components/footer'))
const MiniCart = dynamic(() => import('../components/cart/minicart'))
const Featuredproducts = dynamic(() => import('../components/featured/featuredproducts'))
const Featuredtaxonomies = dynamic(() => import('../components/featured/featuredtaxonomies'))


import { setLatestbloghide } from '../redux/latestbloghide.slice'
import store from '../redux/store';
import { useSelector, useDispatch } from 'react-redux';
import MetaTags from '../components/MetaTags'
import React, { useState, useEffect } from "react";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { useRouter } from 'next/router'

import { removeUser } from '../redux/user.slice';
import { clearShipping } from '../redux/shipping.slice';
import { clearAddresses } from '../redux/address.slice';
//import { disableMobileView } from '../redux/mobileview.slice';

function Home({ item, menu, config, featured_products, featured_taxonomies, sliders, latest_blog_post }) {
  const latestbloghideobject = useSelector((state) => state.latestbloghide);

  const user = useSelector((state) => state.user);
  const cart = useSelector((state) => state.cart);
  const [error, set_error] = useState(false);
  const router = useRouter();
  const dispatch = useDispatch();

  const hide_latest_blog_post = (id) => {
    store.dispatch(setLatestbloghide(id))
  }
  const is_latest_blog_post_hidden = (id) => {
    // check the object for our quantity
    var match = latestbloghideobject.findIndex((element) => element == id)
    if (match >= 0) {
      return true
    } else {
      return false
    }
  }

  //useEffect(() => {
    //dispatch(disableMobileView())
  //},[]);

  useEffect(() => {


    const merchant_api_call = async () => {

      const res = fetch(
        process.env.NEXT_PUBLIC_API_URL + '/validate_cart_merchant',
        {
          body: JSON.stringify({
            cart: cart,
            device_name: 'browser'
          }),
          headers: {
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + token
          },
          method: 'POST'
        }
      )
        .then(response => {
          // reject not ok response
          if (!response.ok) {
            return Promise.reject(response)
          }
          return response.json() // or return response.text()
        })
        .catch(async response => {
          if (!response.ok && response.redirected) {
            set_error('You have logged in on another device, please login again to see pricing')
          }
          const error = await response.json().then(text => text)
          return Promise.reject(error)
        })
        .then(data => {
          set_error(false)
          // herre I need to overwrite the product that we are using with the new data
        })
        .catch((error) => {
          console.warn(error);
        });

    }

    const api_call_basket_validate = async () => {
          if (token) {
              var endpoint = '/validate_cart_user'
              var headers = {
                  'Content-Type': 'application/json',
                  'Authorization': "Bearer " + token
              }
              
            fetch(
              process.env.NEXT_PUBLIC_API_URL + endpoint,
              {
                  body: JSON.stringify({
                      cart: cart
                  }),
                  headers: headers,
                  method: 'POST'
              }
              ) .then(response => {
                // reject not ok response
                if (!response.ok) {
                  return Promise.reject(response)
                }
                return response.json() // or return response.text()
              })
              .catch(async response => {
                if (!response.ok && response.redirected) {
                  set_error('You have logged in on another device, please login again to continue!')
                }
                const error = await response.json().then(text => text)
                return Promise.reject(error)
              })
              .then(data => {
                set_error(false)
                // herre I need to overwrite the product that we are using with the new data
              })
              .catch((error) => {
                console.warn(error);
              });
          } 
        }

    var token = false
    var merchant = false
    if (user[0] != undefined) {
      token = user[0].token
      if (user[0].roles != undefined && user[0].roles.findIndex((element) => element.name == "merchant") >= 0) {
        merchant = true
      }
    }

    if (merchant) {
      // re fetch data for their specific needs
      merchant_api_call()
    }else{
      api_call_basket_validate()
    }

  },);


  const logout_and_redirect_to_login = () => {
    store.dispatch(clearShipping())
    store.dispatch(clearAddresses())
    store.dispatch(removeUser())
    router.push('/login', undefined, { shallow: true })
  }


  return (
    <>

      <MetaTags item={item} config={config}></MetaTags>


      <div className="wrapper h-full flex flex-col">
        <Header menu={menu} config={config}></Header>
        {(latest_blog_post && config.blog_banner_enabled && config.blog_banner_enabled == 'true') ? (
          <>
            {is_latest_blog_post_hidden(latest_blog_post.id) ? ('') : (
              <div className="new-update-background mt-6 lg:container mx-auto flex flex-col lg:flex-row py-3 px-5 justify-between lg:items-center bg-no-repeat bg-cover bg-center lg:rounded-lg relative">
                <div className="text-white text-xl font-medium w-3/4 mb-3 lg:mb-0">{latest_blog_post.name}</div>
                <div className="lg:space-x-7 flex items-center">
                  <Link href={"/blog/" + latest_blog_post.url}>
                    <a className="btn btn-white btn-sm">Read Update</a>
                  </Link>
                  <a
                    onClick={() => hide_latest_blog_post(latest_blog_post.id)}
                    className="cursor-pointer text-white mr-4 mt-4 lg:mt-0 lg:mr-0 lg:static leading-none hover:text-brand-secondary transition duration-150 ease-in-out">
                    <i className="fas fa-times text-xl leading-none"></i>
                  </a>
                </div>
              </div>
            )}
          </>
        ) : ('')}

        <section className="content flex flex-grow flex-col">
          <div className="mt-8 pb-16 lg:pb-32">
            <div className="container mx-auto lg:mb-8">
              <div className="grid lg:grid-cols-3 lg:gap-8 gap-8 mb-8 lg:mb-0">
                <div className="lg:col-span-2 relative lg:order-1 order-2">
                  <Slider sliders={sliders}></Slider>
                </div>
                <div className="mx-5 lg:mx-0 order-1 lg:order-2">
                  <Searcher></Searcher>
                </div>
              </div>
            </div>
            <div className="home-feature-panels">
              {(item && item.description) ? (parse(item.description)) : ('')}
            </div>
          </div>
          {(error) ? (
            <Popup open={true} closeOnDocumentClick={false} closeOnEscape={false} position="right center">
              <div className="bg-white border border-2 p-10 flex flex-col items-center">
                {error}
                <span onClick={() => logout_and_redirect_to_login()} className="mt-5 btn btn-primary p-3 w-40">Login</span>
              </div>
            </Popup>
          ) : ('')}
        </section>
        <Featuredtaxonomies list={featured_taxonomies}></Featuredtaxonomies>
        <Featuredproducts list={featured_products}></Featuredproducts>
        <Footer menu={menu} config={config}></Footer>
      </div>
      <MiniCart></MiniCart>
      <Scripts javascripts={item.javascripts}></Scripts>
    </>
  )
}


// This function gets called at build time on server-side.
// It may be called again, on a serverless function, if
// revalidation is enabled and a new request comes in
export async function getStaticProps(context) {
  const config_res = await fetch(process.env.NEXT_PUBLIC_API_URL + '/configs')
  const config_data = await config_res.json()
  const config = config_data.data

  const menu_res = await fetch(process.env.NEXT_PUBLIC_API_URL + '/menu')
  const menu = await menu_res.json()

  const res = await fetch(process.env.NEXT_PUBLIC_API_URL + '/landingpage/home')
  const response = await res.json()
  const page = response.data

  const featured_res = await fetch(process.env.NEXT_PUBLIC_API_URL + '/featured-products/')
  const featured_response = await featured_res.json()
  const featured_products = featured_response.data

  const featured_tax_res = await fetch(process.env.NEXT_PUBLIC_API_URL + '/featured-taxonomies/')
  const featured_tax_response = await featured_tax_res.json()
  const featured_taxonomies = featured_tax_response.data

  const sliders_res = await fetch(process.env.NEXT_PUBLIC_API_URL + '/sliders/')
  const sliders_response = await sliders_res.json()
  const sliders = sliders_response.data

  const latest_blog_post_res = await fetch(process.env.NEXT_PUBLIC_API_URL + '/latest-blog')
  const latest_blog_post_response = await latest_blog_post_res.json()
  const latest_blog_post = latest_blog_post_response.data

  return {
    props: {
      config: config,
      menu: menu,
      item: page,
      featured_products: featured_products,
      featured_taxonomies: featured_taxonomies,
      sliders: sliders,
      latest_blog_post: latest_blog_post
    },
    // Next.js will attempt to re-generate the page:
    // - When a request comes in
    // - At most once every 10 seconds
    revalidate: 600, // In seconds

  }
}


export default Home
