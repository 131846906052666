import Link from 'next/link'
import { useSelector } from 'react-redux';
import { React, useState, useEffect, useCallback } from 'react'
import Image from 'next/image'

import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
// import "swiper/css";
// import "swiper/css/free-mode"
// import "swiper/css/pagination"

// import Swiper core and required modules
import SwiperCore, {
    Autoplay, 
    Pagination
} from 'swiper';

SwiperCore.use([Pagination, Autoplay]);


function Slider({ sliders }) {

    const [stored_list, set_stored_list] = useState(sliders);

    const useMediaQuery = (width) => {
        const [targetReached, setTargetReached] = useState(false);

        const updateTarget = useCallback((e) => {
            if (e.matches) {
                setTargetReached(true);
            } else {
                setTargetReached(false);
            }
        }, []);

        useEffect(() => {
            const media = window.matchMedia(`(max-width: ${width}px)`)
            media.addEventListener('change', e => updateTarget(e))

            // Check on mount (callback is not called until a change occurs)
            if (media.matches) {
                setTargetReached(true)
            }

            return () => media.removeEventListener('change', e => updateTarget(e))
        }, [updateTarget, width])

        return targetReached;
    };

    const user = useSelector((state) => state.user);
    var token = false
    var merchant = false
    if (user[0] != undefined) {
        token = user[0].token
        if (user[0].roles != undefined && user[0].roles.findIndex((element) => element.name == "merchant") >= 0) {
            merchant = true
        }
    }

    useEffect(() => {
        if (merchant) {
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token
            }
            fetch(
                process.env.NEXT_PUBLIC_API_URL + '/sliders/merchant',
                {
                    headers: headers,
                    method: 'POST'
                }
            ).then(response => {
                // reject not ok response
                if (!response.ok) {
                    return Promise.reject(response)
                }
                return response.json() // or return response.text()
            })
                .catch(async response => {
                    const error = await response.json().then(text => text)
                    return Promise.reject(error)
                })
                .then(data => {
                    // take the original list, and append this info to it
                    var newlist = { ...sliders }
                    newlist = sliders.concat(data.data)
                    set_stored_list(newlist)
                })
                .catch((error) => {
                    console.warn(error);
                });
        }
    }, [token, merchant, sliders]);

    const is_mobile = useMediaQuery(1023)

    return (
        <>
            {(is_mobile) ? (
                <Swiper
                    style={{ 
                        '--swiper-navigation-color': '#fff', 
                        '--swiper-pagination-color': '#fff', 
                        '--swiper-pagination-bullet-height': '16px', 
                        '--swiper-pagination-bullet-width': '16px',
                        '--swiper-pagination-color': '#002E3E',
                        '--swiper-pagination-bullet-inactive-color': '#fff',
                        '--swiper-pagination-bullet-inactive-opacity': '1',
                        '--swiper-pagination-bullet-horizontal-gap': '2px'
                    }}
                    loop={true}
                    autoplay={{ delay: 500 }}
                    speed={1300}
                    pagination={{ clickable: true }}
                    spaceBetween={0}
                    centeredSlides={true}
                    className="homepage-mobile-swiper lg:rounded-lg">
                    {stored_list.map((item) => (
                        item.media.map((image) => (
                            (image.collection_name == 'mobile') ? (
                                <SwiperSlide
                                    key={item.id}
                                >
                                    <Link href={item.link}>
                                        <a className="w-full relative h-full flex flex-col">
                                            <Image
                                                src={image.original_url}
                                                alt={item.name}
                                                width={760}
                                                height={660}
                                                className="bordered-slider object-cover"
                                                placeholder="blur"

                                                blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII="
                                            />
                                        </a>
                                    </Link>
                                </SwiperSlide>
                            ) : ('')
                        ))
                    ))}
                </Swiper>
            ) : (
                <Swiper
                    style={{ 
                        '--swiper-navigation-color': '#fff', 
                        '--swiper-pagination-color': '#fff', 
                        '--swiper-pagination-bullet-height': '16px', 
                        '--swiper-pagination-bullet-width': '16px',
                        '--swiper-pagination-color': '#002E3E',
                        '--swiper-pagination-bullet-inactive-color': '#fff',
                        '--swiper-pagination-bullet-inactive-opacity': '1',
                        '--swiper-pagination-bullet-horizontal-gap': '2px'
                    }}
                    loop={true}
                    autoplay={{ delay: 3000 }}
                    speed={1300}
                    pagination={{ clickable: true }}
                    spaceBetween={0}
                    centeredSlides={true}
                    className="homepage-swiper m-0 lg:rounded-lg h-full w-full">
                    {stored_list.map((item) => (
                        item.media.map((image) => (
                            (image.collection_name == 'default') ? (
                                <SwiperSlide
                                    key={item.id}
                                >
                                    <Link href={item.link}>
                                        <a className="w-full relative h-full flex flex-col">
                                            <Image
                                                src={image.original_url}
                                                alt={item.name}
                                                width={1020}
                                                height={460}
                                                className="bordered-slider object-cover"
                                                placeholder="blur"

                                                blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII="
                                            />
                                        </a>
                                    </Link>
                                </SwiperSlide>
                            ) : ('')
                        ))
                    ))}
                </Swiper>
            )}
        </>
    );
}

export default Slider;